import styles from "./index.module.scss";
import { useEffect, useRef, useState } from "react";
import { getToken, setCallbackUrl, setToken } from "../../utils/cookie.js";
import { Spin } from "antd";
import useUrlState from "@ahooksjs/use-url-state";
import request from "../../utils/request.js";
import api from "../../api/index.js";
import { useHistory } from "react-router-dom";

export default function LoadingPage() {
  const [state, setState] = useUrlState({});
  const history = useHistory();

  // 通过ticket获取token并保存
  const getTokenByTicketFunc = (ticket, redirect) => {
    //wps特殊判断
    if (redirect?.includes("/wps")) {
      redirect = redirect?.split("?")?.at(0);
      redirect = redirect + "?ticket=" + ticket;
      window.location.href = redirect;
      return;
    }
    //如果本地调试直接跳转
    if (redirect?.includes("localhost")) {
      redirect = redirect + "?ticket=" + ticket;
      window.location.href = redirect;
      return;
    }
    let params = {
      params: {
        ticket: ticket,
        redirect: redirect,
        saas: true,
      },
    };
    if (redirect?.includes("?version")) {
      let [a, b] = redirect?.split("?");
      let obj = {};
      b?.split("&")?.forEach((_) => {
        let [c, d] = _?.split("=");
        obj[c] = d;
      });
      const version = obj.version;
      if (version) {
        params.headers = {
          "X-Client-Type": version,
        };
      }
    }
    request.get("sys/login/", params).then((res) => {
      if (res.data.data.status === "redirect") {
        window.location.href = res.data.data.login_url;
      } else if (res.data.data.status === "login") {
        setToken(res.data.data.token || "");
        getJumpProcessFunc(redirect);
      }
    });
  };

  // 内部跳转流程
  const getJumpProcessFunc = (redirect) => {
    let params = {};
    if (redirect?.includes("?version")) {
      let [a, b] = redirect?.split("?");
      let obj = {};
      b?.split("&")?.forEach((_) => {
        let [c, d] = _?.split("=");
        obj[c] = d;
      });
      const version = obj.version;
      if (version) {
        params.headers = {
          "X-Client-Type": version,
        };
      }
    }
    const getCodePromise = request.get("/saas/prd_code/", {
      ...params,
      params: {
        link: redirect,
      },
    });
    const getMenuPromise = request.get("/sys/user/menu/", params);
    Promise.all([getCodePromise, getMenuPromise]).then((_) => {
      const [codeRes, menuRes] = _;
      const menuList = menuRes?.data?.data;
      const code = codeRes?.data?.data?.prd_code;
      const menusItem = menuList?.find((_) => _.menu_code === code);
      if (menusItem) {
        window.location.href = redirect;
      } else {
        // 没有项目权限
        let path = "/saas/guidePage";
        if (code) {
          setCallbackUrl(redirect)
          path =
            path +
            "?project=" +
            (code?.split("_") || [])?.at(0) +
            "&fromLogin=true";
          const to = localStorage.getItem("to");
          if (to) {
            localStorage.removeItem("to");
            history.push(to);
            return;
          }
        }
        history.push(path);
      }
    });
  };

  useEffect(() => {
    if (getToken()) {
      getJumpProcessFunc(state.redirect);
      return;
    }
    getTokenByTicketFunc(state.ticket, state.redirect);
  }, []);

  return (
    <div className={styles.root}>
      <Spin></Spin>
    </div>
  );
}
